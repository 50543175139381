import "./prizePage.css";
import Rose from "../../images/RoseImg.png";
import everyDayMobile from "../../images/everyDayMobile.png";
import packageMobile from "../../images/packageMobile.png";
import packageDesc from "../../images/packageDesc.png";
import everyWeekMobile from "../../images/everyWeekMobile.png";
import travelMobile from "../../images/travelMobile.png";
import line from "../../images/line.png";
import lineRightDesc from "../../images/lineRightDesc.png";
import lineDownDesc from "../../images/lineDownDesc.png";
import lineLeftDesc from "../../images/lineLeftDesc.png";
import travelDesc from "../../images/travelDesc.png";
import everyWeekDesc from "../../images/everyWeekDesc.png";
import everyDayDesc from "../../images/everyDayDesc.png";
import { useMediaQuery } from "react-responsive";

const PrizePage = (props) => {
  const IsDesktop = useMediaQuery({
    query: '(min-width: 1024px)'
  })
  return (
    <div className="prizeContainer">
      {/* Mobile version */}
      <img src={Rose} alt="" className="roseImgRules"></img>
      <img src={travelMobile} alt="" className="travelMobile"></img>
      <img src={everyWeekMobile} alt="" className="everyWeekMobile"></img>
      <img src={everyDayMobile} alt="" className="everyDayMobile"></img>
      <img src={packageMobile} alt="" className="packageMobile"></img>
      <img src={line} alt="" className="line"></img>
      {/* Desktop version */}
      <div className="desktop">
        <img src={travelDesc} alt="" className="travelDesc"></img>
        <img src={everyWeekDesc} alt="" className="everyWeekDesc"></img>
        <img src={lineRightDesc} alt="" className="lineRightDesc"></img>
      </div>
      {IsDesktop && (<img src={lineLeftDesc} alt="" className="lineLeftDesc"></img>)}
      <div className="desktop">
        <img src={everyDayDesc} alt="" className="everyDayDesc"></img>
        <img src={packageDesc} alt="" className="packageDesc"></img>
        <img src={lineDownDesc} alt="" className="lineRightDown"></img>
      </div>
    </div>
  );
};

export default PrizePage;
