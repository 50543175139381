import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import "./App.css";
import SoonPage from "./components/soonPage";
import FormPage from "./components/formPage/formPage";
import RulesPage from "./components/rulesPage/rulesPage";
import PrizePage from "./components/prizePage/prizePage";
import Rose from "./images/RoseImg.png";
import { useMediaQuery } from "react-responsive";
import ContactPage from "./components/contactPage/contactPage";
import MechanismPage from "./components/mechanismPage/mechanismPage";
import WinnersPage from "./components/winnersPage/winnersPage";
import HamburgerMenu from "./components/hamburgerMenu/hamburgerMenu";
import CookieComponent from "./components/cookies/cookieComponent";
import Popup from "./components/popup/popup";

function App() {
  const [dateGameOver] = useState(new Date('2023-12-17 23:59:59'));
  const currentDate = new Date();
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [activeDiv, setActiveDiv] = useState(null);
  // const [message, setMessage] = useState("");
  const [response, setResponse] = useState({ responseCode: "", pfr: "" });
  const openPopup = () => setPopup(true);
  const closePopup = () => setPopup(false);
  // const handleMessage = (text) => setMessage(text);
  const handleResponse = (payload) => setResponse(payload);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // COOKIES
  const [showCookieComponent, setShowCookieComponent] = useState(true);
  // const handleCookieComponent = () =>
  //   setShowCookieComponent(!showCookieComponent);

  const closeCookieComponent = () => {
    setShowCookieComponent(false);
  };

  useEffect(() => {
    localStorage.setItem("cookies-acceptance", true);
    if (
      localStorage.getItem("cookies-analytics") ||
      localStorage.getItem("cookies-marketing")
    ) {
      setShowCookieComponent(false);
    }
  }, []);

  const handleDivClick = (divId) => {
    setActiveDiv(divId);
  };



  return (
    currentDate < dateGameOver ? (
      <BrowserRouter>
        {!isDesktop && (
          <div>
            <button onClick={toggleMenu} className="hamburger-button">
              ☰
            </button>
            {isMenuOpen && (
              <>
                <img src={Rose} alt="" className="roseImgSideBarMobile"></img>
                <div className="menu">
                  <ul className="menuItems">
                    <div
                      className={`${activeDiv === "div1Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div1Mobile")}>
                      {/* <button className="buttonHidden"> */}
                      <Link
                        to="/ucestvuj"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        UČESTVUJ
                      </Link>
                      {/* </button> */}
                    </div>
                    <div
                      className={`${activeDiv === "div2Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div2Mobile")}>
                      {/* <button className="buttonHidden"> */}
                      <Link
                        to="/mehanizam"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        MEHANIZAM
                      </Link>
                      {/* </button> */}
                    </div>
                    <div
                      className={`${activeDiv === "div3Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div3Mobile")}>
                      {/* <button  className="buttonHidden"> */}
                      <Link
                        to="/nagrade"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        NAGRADE
                      </Link>
                      {/* </button> */}
                    </div>
                    <div
                      className={`${activeDiv === "div4Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div4Mobile")}>
                      {/* <button className="buttonHidden"> */}
                      <Link
                        to="/dobitnici"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        DOBITNICI
                      </Link>
                      {/* </button> */}
                    </div>
                    <div
                      className={`${activeDiv === "div5Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div5Mobile")}>
                      {/* <button className="buttonHidden"> */}
                      <Link
                        to="/pravila"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        PRAVILA
                      </Link>
                      {/* </button> */}
                    </div>
                    <div
                      className={`${activeDiv === "div6Mobile"
                        ? "linksMobile"
                        : "clickable-divMobile"
                        }`}
                      onClick={() => handleDivClick("div6Mobile")}>
                      {/* <button  className="buttonHidden"> */}
                      <Link
                        to="/kontakt"
                        className="linkTxt"
                        onClick={toggleMenu}>
                        KONTAKT
                      </Link>
                      {/* </button> */}
                    </div>
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
        <div className={!isMenuOpen ? "mainPages" : "mainPagesClosed"}>
          <HamburgerMenu />
          {isDesktop && (
            <div className="sideBar">
              <div>
                <img src={Rose} alt="" className="roseImgSideBar"></img>
                <div className="contentContainer">
                  <div
                    className={`${activeDiv === "div1" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div1")}>
                    <Link to="/ucestvuj" className="linkTxt">
                      UČESTVUJ
                    </Link>
                  </div>
                  <div
                    className={`${activeDiv === "div2" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div2")}>
                    <Link to="/mehanizam" className="linkTxt">
                      MEHANIZAM
                    </Link>
                  </div>
                  <div
                    className={`${activeDiv === "div3" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div3")}>
                    <Link to="/nagrade" className="linkTxt">
                      NAGRADE
                    </Link>
                  </div>
                  <div
                    className={`${activeDiv === "div4" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div4")}>
                    <Link to="/dobitnici" className="linkTxt">
                      DOBITNICI
                    </Link>
                  </div>
                  <div
                    className={`${activeDiv === "div5" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div5")}>
                    <Link to="/pravila" className="linkTxt">
                      PRAVILA
                    </Link>
                  </div>
                  <div
                    className={`${activeDiv === "div6" ? "links" : "clickable-div"
                      }`}
                    onClick={() => handleDivClick("div6")}>
                    <Link to="/kontakt" className="linkTxt">
                      KONTAKT
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
          <Routes>
            <Route
              path="ucestvuj"
              element={
                <FormPage openPopup={openPopup} handleResponse={handleResponse} />
              }
            />
            <Route path="mehanizam" element={<MechanismPage />} />
            <Route path="nagrade" element={<PrizePage />} />
            <Route path="dobitnici" element={<WinnersPage />} />
            <Route path="pravila" element={<RulesPage />} />
            <Route path="kontakt" element={<ContactPage />} />
            <Route path="*" element={<Navigate to="ucestvuj" replace />} />
          </Routes>
        </div>
        {popup && <Popup closePopup={closePopup} response={response} />}
        {showCookieComponent && (
          <CookieComponent closeCookieComponent={closeCookieComponent} />
        )}
      </BrowserRouter>
    ) : (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<WinnersPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    )
  );
}

export default App;
