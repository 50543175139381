import React, { useState, useEffect } from "react";
import "./cookieComponent.css";

const CookieComponent = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [secondCheckbox, setSecondCheckbox] = useState("");
  const [thirdCheckbox, setThirdCheckbox] = useState("");

  const handleSecondCheckbox = () => {
    if (!secondCheckbox) {
      setSecondCheckbox("YES");
    } else {
      setSecondCheckbox(false);
    }
  };

  const handleThirdCheckbox = () => {
    if (!thirdCheckbox) {
      setThirdCheckbox("YES");
    } else {
      setThirdCheckbox(false);
    }
  };

  const acceptAll = () => {
    localStorage.setItem("cookies-analytics", true);
    localStorage.setItem("cookies-marketing", true);
    // window.analytics();
    props.closeCookieComponent();
  };

  const acceptSpecific = () => {
    secondCheckbox
      ? localStorage.setItem("cookies-analytics", true)
      : localStorage.setItem("cookies-analytics", false);
    thirdCheckbox
      ? localStorage.setItem("cookies-marketing", true)
      : localStorage.setItem("cookies-marketing", false);
    // window.analytics();
    props.closeCookieComponent();
  };

  useEffect(() => {
    if (localStorage.getItem("cookies-analytics") === "true") {
      setSecondCheckbox("YES");
    }
    if (localStorage.getItem("cookies-marketing") === "true") {
      setThirdCheckbox("YES");
    }
  }, [1]);

  return (
    <div className="cookieContainer">
      <div className="cookie">
        <div className="base">
          <div className="text">
            Koristimo kolačiće kako bismo vam omogućili što bolje iskustvo na
            ovom sajtu. Klikom na "prilagodi podešavanja" možete njima
            upravljati. Informacije o korišćenju sajta delimo sa partnerima za
            društvene mreže, oglašavanje i analitiku. Nastavljajući da koristite
            ovaj sajt saglasni ste sa korišćenjem kolačića u skladu sa &nbsp;
            <a
              href="https://najlepsezelje.com/politika-privatnosti"
              target="_blank">
              pravilima.
            </a>
          </div>

          <div className="expand" onClick={() => setIsExpanded(!isExpanded)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              transform={`${isExpanded ? "rotate(180)" : "rotate(0)"}`}>
              <g clip-path="url(#clip0_1460_4071)">
                <path
                  d="M24.0898 24.4199L0.0898438 24.4199L0.0898437 0.419922L24.0898 0.419922L24.0898 24.4199Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M17.0898 14.4199L12.0898 9.41992L7.08984 14.4199L17.0898 14.4199Z"
                  fill="#4A4A4A"
                />
              </g>
              <defs>
                <clipPath id="clip0_1460_4071">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 -1 24.0898 24.4199)"
                  />
                </clipPath>
              </defs>
            </svg>
            Prilagodite podešavanja
          </div>

          <div
            className="btn"
            onClick={isExpanded ? acceptSpecific : acceptAll}>
            {isExpanded ? "sačuvaj podešavanja" : "prihvati sve"}
          </div>
        </div>

        {isExpanded && (
          <div className="expanded">
            <div className="field">
              <input type="checkbox" checked="YES" />
              <span className="checkmark"></span>
              <div className="info">
                <div className="title">Neophodni kolačići</div>
                <div className="text">
                  Neophodni kolačići omogućavaju osnovne funkcionalnosti.
                  Internet stranica ne može pravilno funkcionisati bez kolačića
                  i oni se mogu isključiti samo promenom podešavanja
                  pretraživača.
                </div>
              </div>
            </div>

            <div className="field" onClick={handleSecondCheckbox}>
              <input type="checkbox" checked={secondCheckbox} />
              <span class="checkmark"></span>
              <div className="info">
                <div className="title">Analitički kolačići</div>
                <div className="text">
                  Analitički kolačići nam pomažu da poboljšamo našu internet
                  stranicu prikupljanjem podataka i izveštavanjem o njihovoj
                  upotrebi.
                </div>
              </div>
            </div>

            <div className="field" onClick={handleThirdCheckbox}>
              <input type="checkbox" checked={thirdCheckbox} />
              <span class="checkmark"></span>
              <div className="info">
                <div className="title">Marketinški kolačići</div>
                <div className="text">
                  Marketinške kolačiće koristimo radi poboljšanja relevantnosti
                  reklamnih kampanja koje dobijate.
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CookieComponent;
