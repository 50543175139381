const useGTM = () => {
  let handleGTM = () => { };

  if (window.dataLayer) {
    const handleGTMSignUp = () => window.dataLayer.push({
      event: 'GTMEvent',
      eventCategory: 'Forms',
      eventAction: 'OK',
      eventLabel: 'putuj_punim_srcem'
    });

    handleGTM = (type) => {
      switch (type) {
        case 'signup':
          return handleGTMSignUp();
        default:
          break;
      }
    }
  }

  return [
    handleGTM
  ]
};

export default useGTM;