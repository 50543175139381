import axios from 'axios';
import qs from 'qs';
import {
  apiUrl,
  getBasicAuthHeader,
  submitFormUrl,
  winnersUrl,
  submitFormUrlInfoBip,
  username,
  password,
  infoBipApiUrl,
  infoBipApiKey,
  getInfoBipAuthHeader,
  getNewDateInYyyyMmDdFormat,
} from './helpers';


const apiInstance = axios.create({
  baseURL: apiUrl,
  headers: {
    Authorization: getBasicAuthHeader(username, password),
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  }
});

const infoBipInstance = axios.create({
  baseURL: infoBipApiUrl,
  headers: {
    Authorization: getInfoBipAuthHeader(infoBipApiKey),
    'Content-Type': 'application/json',
  }
})

const formSubmit = async (payload) => {
  const queryString = qs.stringify(payload);
  const { data } = await apiInstance.get(`/${submitFormUrl}?${queryString}`);
  return data;
}

const getWinners = async () => {
  const { data } = await apiInstance.get(`${winnersUrl}`);
  return data;
}

const formSubmitToInfoBip = async (payload) => {
  const { email, mobile } = payload;
  const emailAddress = email ? email : '';
  const mobileNumber = '381' + mobile.substring(1);
  const dataToSend = {
    "country": "RS",
    "customAttributes": {
      "Language": "SR",
      "Brand": "NajlepšeŽelje",
      "Opt-in date": getNewDateInYyyyMmDdFormat(),
      "Creation date": getNewDateInYyyyMmDdFormat(),
      "GDPR consent permission": true,
      "Email consent": true,
      "Campaign name": [{
        "Topic": "NŽ_UniverexportNCP"
      }]
    },
    "contactInformation": {
      "email": [{
        "address": emailAddress
      }],
      "phone": [{
        "number": mobileNumber
      }]
    }
  };
  try {
    const response = await infoBipInstance.post(`/${submitFormUrlInfoBip}`, dataToSend);
    return response;
  } catch (error) {
    console.log('error ', error)
  }
}

export { formSubmit, getWinners, formSubmitToInfoBip }
