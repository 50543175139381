import React, { useState } from "react";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "./rulesPage.css";
import Reward from "../../images/nagradjujemo.png";
import Rose from "../../images/RoseImg.png";
import travelImgDesc from "../../images/travelImgDesc.png";
import ChocolateLeft from "../../images/chocolateLeftRules.png";
import { pdfjs, Document, Page } from "react-pdf";
import rulesPDF from "../../images/rules.pdf";
import rulesPDFMobile from "../../images/rules-mobile.pdf";
import { Viewer } from "@react-pdf-viewer/core";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { useMediaQuery } from "react-responsive";
<Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js"></Worker>;

const options = {
  cMapUrl: "/cmaps/",
  standardFontDataUrl: "/standard_fonts/",
};

const RulesPage = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function previousPage() {
    setPageNumber((prevPage) => (prevPage !== 1 ? prevPage - 1 : 1));
  }

  function nextPage() {
    setPageNumber((prevPage) =>
      prevPage !== numPages ? prevPage + 1 : numPages
    );
  }

  return (
    <div>
      <div className="rulesTitle">Nagrađujemo</div>
      <label className="rulesSubtitle">Putuj punim srcem</label>
      <div className="labelRules">PRAVILA</div>
      <div className="mainContainerRules">
        <div>
          <img src={travelImgDesc} alt="" className="travelImgDescRules"></img>
        </div>
        <div className="rosePictureRules">
          {" "}
          <img src={Rose} alt="" className="roseImgFormRules"></img>
        </div>
        <div className="rewardTxtMobileRules">
          <img src={Reward} alt="" className="rewardTxtRules"></img>
        </div>
        <div className="inputWrapperRules">
          <div className="inputLabelRules"></div>
          <div className="formWrapperRules">
            {/* <div className="inputFieldRules">
              <Document
                file={rulesPDFMobile}
                onLoadSuccess={onDocumentLoadSuccess}
                options={options}
                loading={<span class="loader"></span>}
                noData={<span class="loader"></span>}>
                <Page
                  pageNumber={pageNumber}
                  renderTextLayer={true}
                  renderAnnotationLayer={true}
                  customTextRenderer={false}
                  loading={<span class="loader"></span>}
                  noData={<span class="loader"></span>}
                />
              </Document>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}>
                <button
                  type="button"
                  disabled={pageNumber <= 1}
                  onClick={previousPage}
                  className="linkTxtRules">
                  Prethodna
                </button>
                <div
                  className="rulesSubtitle"
                  style={{ fontSize: 18, marginTop: 0 }}>
                  Strana {pageNumber || (numPages ? 1 : "--")} of{" "}
                  {numPages || "--"}
                </div>
                <button
                  type="button"
                  disabled={pageNumber >= numPages}
                  onClick={nextPage}
                  className="linkTxtRules">
                  Sledeća
                </button>
              </div>
            </div> */}
            <div className="inputFieldRules">
              <Viewer
                fileUrl={
                  !isDesktop ? "assets/rules-mobile.pdf" : "assets/rules.pdf"
                }
                defaultScale={!isDesktop ? "PageFit" : "ActualSize"}
                enableSmoothScroll={true}
              />
            </div>
          </div>
        </div>
        <div>
          <img src={ChocolateLeft} alt="" className="chocolateLeftDesc"></img>
        </div>
      </div>
    </div>
  );
};

export default RulesPage;
