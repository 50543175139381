import React from "react";
import "./popup.css";

<a href="kontaktcentarSRB@atlanticgrupa.com">kontaktcentarSRB@atlanticgrupa.com</a>;
const Popup = (props) => {
  const { response: { responseCode, pfr } } = props;

  const wrongCode = `Vaša prijava nije prihvaćena. Proverite unos podataka i pokušajte ponovo ili pozovite 0800 200 003. Hvala što učestvujete!`;

  const usedCode =
    'Ova prijava je već iskorišćena. U toku nagradne igre jedna ista prijava se ne može koristiti više puta. Za više informacija pozovite 0800 200 003. Hvala!';

  return (
    <div className="popup">
      <div className="content">
        {responseCode !== "01" && (
          <div className="err">
            {responseCode === "02" ? (
              <span dangerouslySetInnerHTML={{ __html: wrongCode }}></span>
            ) : responseCode === "03" ? (
              <span dangerouslySetInnerHTML={{ __html: usedCode }}></span>
            ) : (
              "Something wrong happened, please try again later."
            )}
          </div>
        )}

        {responseCode === "01" && (
          <div className="success">
            <div>Vaša prijava <u>{pfr}</u> je prihvaćena.</div>
            <div>
              Sačuvajte račun do preuzimanja nagrade u slučaju dobitka! Hvala!
            </div>
          </div>
        )}

        <svg
          className="close"
          onClick={props.closePopup}
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.0433 11.6749L0.325074 0.956664L1.21826 0.0634766L11.9365 10.7817L11.0433 11.6749Z"
            fill="#383838"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6114 0.893201L0.893188 11.6114L0 10.7183L10.7182 1.35199e-05L11.6114 0.893201Z"
            fill="#383838"
          />
        </svg>
      </div>
    </div>
  );
};

export default Popup;
