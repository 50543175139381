import "./contactPage.css";
import emptyHeartMobile from "../../images/emptyHeartImg.png";
import Rose from "../../images/RoseImg.png";
import Reward from "../../images/nagradjujemo.png";
import travelImgDesc from "../../images/travelImgDesc.png";
import chocolateLeftDesc from "../../images/chocolateLeftDesc.png";
import HamburgerMenu from "../hamburgerMenu/hamburgerMenu";

const ContactPage = (props) => {
  return (
    <div>
      <div className="rulesTitle">Nagrađujemo</div>
      <label className="rulesSubtitle">Putuj punim srcem</label>
      <div className="labelContact">KONTAKTIRAJTE NAS</div>
      <div className="rosePictureRules">
        {" "}
        <img src={Rose} alt="" className="roseImgFormRules"></img>
      </div>
      <div className="rewardTxtMobileRules">
        <img src={Reward} alt="" className="rewardTxtRules"></img>
      </div>
      <div className="contactPageContainer">
        <img
          src={chocolateLeftDesc}
          alt=""
          className="chocolateLeftDescContact"></img>
        <img src={emptyHeartMobile} alt="" className="emptyHeartMobile"></img>
        <img src={travelImgDesc} alt="" className="travelImgDescContact"></img>

        <div className="contactTxt">KONTAKT BROJ</div>
        <div className="contactNumber">0800 200 003</div>
        <div className="email">EMAIL ADRESA</div>
        <a className="link" href="mailto:kontaktcentarSRB@atlanticgrupa.com">
          kontaktcentarSRB@atlanticgrupa.com
        </a>
      </div>
    </div>
  );
};

export default ContactPage;
