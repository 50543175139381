import React from "react";

const WinnerCard = (props) => {
  const {
    telefon,
    nagrada,
    datum,
    tip,
    pfr,
  } = props;

  const prizeType = {
    day: 'DNEVNA',
    week: 'NEDELJNA',
    final: 'GLAVNA'
  }

  return (
    <div>
      <div className="inputFieldWinners">
        <div className="rewardWrap">
          {/* <div className="winnerTxt">{prizeType[tip]} NAGRADA</div>
          <div>{datum}</div> */}
        </div>
        <div className="rewardWrapBottom">
          <div className="telefonWinner">{telefon}</div>
          <div className="gift">{pfr}</div>
        </div>
      </div>
    </div>
  )
}

export default WinnerCard