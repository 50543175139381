const {
  REACT_APP_API_URL: apiUrl,
  REACT_APP_API_USERNAME: username,
  REACT_APP_API_PASSWORD: password,
  REACT_APP_INFOBIP_API_URL: infoBipApiUrl,
  REACT_APP_INFOBIP_API_KEY: infoBipApiKey,
} = process.env;

const submitFormUrl = '127nzcode';
const winnersUrl = '127nzwinners';
const submitFormUrlInfoBip = 'people/2/persons';

// Authorization base64 helper method
const getBasicAuthHeader = (username, password) => {
  const credentials = `${username}:${password}`;
  const encodedCredentials = btoa(credentials);
  return `Basic ${encodedCredentials}`;
};

const getInfoBipAuthHeader = (apiKey) => {
  return `App ${apiKey}`
};

const getNewDateInYyyyMmDdFormat = () => {
  let date = new Date();
  return date.toISOString().split('T')[0]
};

export {
  apiUrl,
  username,
  password,
  getBasicAuthHeader,
  submitFormUrl,
  winnersUrl,
  submitFormUrlInfoBip,
  getInfoBipAuthHeader,
  getNewDateInYyyyMmDdFormat,
  infoBipApiUrl,
  infoBipApiKey,
};
