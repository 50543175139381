import React, { useState, useEffect } from "react";
import "./winnersPage.css";
import Reward from "../../images/nagradjujemo.png";
import Rose from "../../images/RoseImg.png";
import travelImgDesc from "../../images/travelImgDesc.png";
import ChocolateLeft from "../../images/chocolateLeftRules.png";
import { useMediaQuery } from "react-responsive";
import { getWinners } from "../../API";
import WinnerCard from "./winnerCard";


const WinnersPage = (props) => {
  const [winners, setWinners] = useState();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchWinners = async () => {
      const results = await getWinners();
      setWinners(results);
      setLoading(false);
    };
    fetchWinners();
  }, []);

  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <div>
      <div className="rulesTitle">Nagrađujemo</div>
      <label className="rulesSubtitle">Putuj punim srcem</label>
      <div className="labelRules">LISTA DOBITNIKA</div>
      <div className="mainContainerRules">
        <div>
          <img
            src={travelImgDesc}
            alt=""
            className="travelImgDescWinners"></img>
        </div>
        <div className="rosePictureRules">
          {" "}
          <img src={Rose} alt="" className="roseImgFormRules"></img>
        </div>
        <div className="rewardTxtMobileRules">
          <img src={Reward} alt="" className="rewardTxtRules"></img>
        </div>

        {loading === true && <span class="loader"></span>}

        {loading === false && (
          <div className="formWrapperRules">
            {winners?.map(winner => (
              <div className="inputWrapperRules">
                <WinnerCard
                  telefon={winner.telefon}
                  nagrada={winner.nagrada}
                  datum={winner.datum}
                  tip={winner.tip}
                  pfr={winner.pfr}
                />
              </div>
            ))}
          </div>
        )}
        <div>
          <img src={ChocolateLeft} alt="" className="chocolateLeftDesc"></img>
        </div>
      </div>
    </div>
  );
};

export default WinnersPage;
