import React, { useState, useEffect } from "react";
import "./formPage.css";
import Rose from "../../images/RoseImg.png";
import travelImgDesc from "../../images/travelImgDesc.png";
import { formSubmit, formSubmitToInfoBip } from "../../API";
import useGTM from "../../hooks/useGTM";

const FormPage = (props) => {
  const [handleGTM] = useGTM();


  const mailRegex =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
  const codeRegex = /^([a-zA-Z0-9]{8})-([a-zA-Z0-9]{8})-([0-9]{1,8})$/;
  const phoneRegex = /^06\d*$/;
  const SERBIA_DIAL_CODE = "00381";

  const [isChecked, setIsChecked] = useState(false);
  const [isValidated, setIsValidated] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [redCheckBox, setRedCheckBox] = useState(false);

  const [isBlured, setIsBlured] = useState({
    email: "",
    code: "",
    phone: "",
  });

  const [isFocused, setIsFocused] = useState({
    email: "",
    code: "",
    phone: "",
  });

  const [values, setValues] = useState({
    email: "",
    code: "",
    phone: "",
  });

  const handleField = (name, newValue) => {
    let temp = { ...values };
    temp[name] = newValue;
    setValues(temp);
  };

  const handleCheckbox = () => {
    if (isChecked) {
      setIsChecked(false);
      setRedCheckBox(isChecked);
    } else {
      setIsChecked(true);
      setRedCheckBox(!redCheckBox);
    }
  };

  const handleBlur = (e) => {
    let copyBlur = { ...isBlured };
    let copyFocused = { ...isFocused };
    // Change state wheh input is blured
    copyBlur[e.target.name] = true;
    setIsBlured(copyBlur);

    // After blur element will defocus so we change state here
    copyFocused[e.target.name] = false;
    setIsFocused(copyFocused);
  };

  const handleFocus = (e) => {
    let copy = { ...isFocused };
    copy[e.target.name] = true;
    setIsFocused(copy);
  };

  const handleSubmit = async () => {
    const formPayload = {
      msisdn: SERBIA_DIAL_CODE + values.phone.substring(1),
      mobile: values.phone,
      code: values.code,
      email: values.email,
      consent: isChecked ? "YES" : "NO",
    };

    let response;
    if (isValidated === "true") {
      if (isChecked) {
        response = await formSubmit(formPayload);
        let infoBipResponse = await formSubmitToInfoBip({
          email: formPayload.email,
          mobile: formPayload.mobile,
        });
      } else {
        response = await formSubmit(formPayload);
      }
      const { code: responseCode } = response.response;
      if (responseCode === '01') {
        handleGTM('signup');
      }
      props.handleResponse({ responseCode, pfr: formPayload.code });
      props.openPopup();
      if (response.success) {
        setIsBlured({
          email: "",
          code: "",
          phone: "",
        });
        setValues({
          email: "",
          code: "",
          phone: "",
        });
        alert(response.success);
      } else if (response.error) {
        setErrMsg(response.error.message);
        setTimeout(() => {
          setErrMsg("");
        }, "5000");
      }
    } else {
      setIsBlured({
        code: true,
        email: true,
        phone: true,
      });
    }
  };

  useEffect(() => {
    if (
      values.code.length < 1 ||
      !codeRegex.test(values.code) ||
      !phoneRegex.test(values.phone) ||
      values.phone.length < 9 ||
      values.phone.length > 10
    ) {
      setIsValidated("false");
    } else {
      setIsValidated("true");
    }
  }, [values.code, values.phone]);

  return (
    <div className="mainContainer">
      <div>
        <img src={travelImgDesc} alt="" className="travelImgDesc"></img>
      </div>
      <div className="rosePicture">
        {" "}
        <img src={Rose} alt="" className="roseImgForm"></img>
      </div>
      <div className="inputWrapper">
        <div className="inputLabel">*BROJ MOBILNOG TELEFONA</div>
        <div className="formWrapper">
          <input
            type="text"
            id="id"
            name="phone"
            className="inputField"
            placeholder="06XXXXXXX"
            value={values.phone}
            onChange={(e) => handleField("phone", e.target.value)}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)}
            inputErr={
              isBlured.phone &&
                (!phoneRegex.test(values.phone) ||
                  values.phone.length < 9 ||
                  values.phone.length > 10)
                ? "true"
                : ""
            }
          />
        </div>
        {isBlured.phone &&
          (!phoneRegex.test(values.phone) ||
            values.phone.length < 9 ||
            values.phone.length > 10) && (
            <span className="errorMessage">
              {" "}
              {!phoneRegex.test(values.phone)
                ? "Ovo polje mora da počinje sa 0."
                : values.phone.length < 9 || values.phone.length > 10
                  ? "Broj telefona mora sadržati 9 ili 10 cifara"
                  : ""}
            </span>
          )}
        <div className="inputLabel">*PFR BROJ RAČUNA</div>
        <div className="formWrapper">
          <input
            type="text"
            className="inputFieldSecond"
            placeholder="Ukucaj PFR broj RAČUNA"
            name="code"
            value={values.code}
            onChange={(e) => handleField("code", e.target.value)}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)}
            inputErr={
              isBlured.code && !codeRegex.test(values.code) ? "true" : ""
            }
          />
        </div>
        {isBlured.code && !codeRegex.test(values.code) && (
          <span className="errorMessage">
            {" "}
            {codeRegex.test(values.code)
              ? ""
              : "Unesite broj PFR računa u pravilnom formatu."}
          </span>
        )}

        <div className="inputLabel">EMAIL ADRESA</div>
        <div className="formWrapper">
          <input
            type="text"
            name="email"
            className="inputField"
            placeholder="petarpetrovic@email.com"
            value={values.email}
            onChange={(e) => handleField("email", e.target.value)}
            onBlur={(e) => handleBlur(e)}
            onFocus={(e) => handleFocus(e)}
            inputErr={
              isBlured.email && !mailRegex.test(values.email) ? "true" : ""
            }
          />
          {/* {isBlured.email && !mailRegex.test(values.email) && ( */}
        </div>
        {values.email && (
          <span className="errorMessage">
            {" "}
            {mailRegex.test(values.email)
              ? ""
              : "Unesite email adresu u pravilnom obliku"}
          </span>
        )}
        <div className="checkBoxContainer">
          <input
            type="checkbox"
            className="checkBox"
            checked={isChecked}
            onChange={handleCheckbox}></input>
          <label className="labelCheckbox">
            Saglasan/na sam da povezane kompanije
            Atlantic Grupe razmenjuju, obrađuju i koriste moje lične podatke,
            uključujući imejl, u skladu sa&nbsp;
            <a
              href="https://najlepsezelje.com/politika-privatnosti"
              target="_blank"
              style={{ color: "#fff" }}>
              Pravilima o zaštiti privatnosti
            </a>
            &nbsp;za promotivne aktivnosti brendova dostupnih na
            www.atlanticgrupa.com, kao i za analitičke i statističke potrebe, i
            za pravljenje i obaveštavanje o prilagođenim individualizovanim
            prilikama, akcijama ili ponudama određenog brenda i brendova
            povezanih kompanija, na osnovu posebnog profila formiranog
            korišćenjem napredne tehnologije i automatizovane obrade mojih
            ličnih podataka. Ova saglasnost se odnosi na svaki brend u periodu
            do povlačenja ovih prava za određeni brend.
          </label>
        </div>
        <div className="submitContainer">
          <label className="label">*Obavezna polja za popunjavanje.</label>
          <button type="button" className="submitBtn" onClick={handleSubmit}>
            PRIJAVI SE
          </button>
        </div>
      </div>
    </div>
  );
};
export default FormPage;
