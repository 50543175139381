import "./mechanismPage.css";
import Rose from "../../images/RoseImg.png";
import buyOneDesc from "../../images/buyOneDesc.png";
import sendSecondDesc from "../../images/sendSecondDesc.png";
import winThirdDesc from "../../images/winThirdDesc.png";
import { useMediaQuery } from "react-responsive";

const MechanismPage = (props) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <div className="prizeContainer">
      {/* Mobile version */}
      <img src={Rose} alt="" className="roseImgRules"></img>
      {/* Desktop version */}
      <div className="desktopFirst">
        <img src={buyOneDesc} alt="" className="buyOneDesc"></img>
        <img src={sendSecondDesc} alt="" className="sendSecondDesc"></img>
      </div>
      <div className="desktopSecond">
        <img src={winThirdDesc} alt="" className="winThirdDesc"></img>
        <div className="smsContainer">
          Cena SMS poruke se tarifira za krajnjeg korisnika po standardnoj ceni
          SMS poruke operatera mreže čiji je korisnik pretplatnik: za korisnike
          "MTS" mobilne mreže 3,60 dinara, za korisnike "Yettel" mobilne mreže
          3,99 dinara, za korisnike "A1" mobilne mreže 3,48 dinara, a za
          korisnike "Globatel" mreže iznosi 4,99 dinara sa uračunatim PDV-om.
          Operateri, shodno svojoj komercijalnoj politici, zadržavaju pravo na
          promenu standardne cene SMS poruke koja se tarifira za krajnjeg
          korisnika i Priređivač ne garantuje cene navedene u ovom Pravilniku i
          ne može snositi odgovornost za eventualne promene cena od strane gore
          navedenih operatera. Prijava putem internet stranice je besplatna.
        </div>
      </div>
      {/* <div> */}
      <div className="lastTxt">Obavezno sačuvati fiskalni račun.</div>
      {isDesktop && (
        <div className="lastTxt">
          Više informacija: &nbsp;
          <a
            className="footerLinks"
            href="https://www.najlepsezelje.rs"
            target="_blank">
            www.najlepsezelje.rs
          </a>{" "}
          | &nbsp;
          <a
            className="footerLinks"
            href="https://www.univerexport.rs"
            target="_blank">
            www.univerexport.rs
          </a>{" "}
          | 0800 200 003
        </div>
      )}
      {!isDesktop && (
        <div>
          {" "}
          <div className="lastTxt">Više informacija:</div>
          <div className="lastTxtMobileContainer">
            <div>
              {" "}
              <a
                className="footerLinks"
                href="https://www.najlepsezelje.rs"
                target="_blank">
                www.najlepsezelje.rs
              </a>{" "}
              | &nbsp;
            </div>
            <div>
              {" "}
              <a
                className="footerLinks"
                href="https://www.univerexport.rs"
                target="_blank">
                www.univerexport.rs
              </a>{" "}
            </div>
            <div>&nbsp;| 0800 200 003</div>
          </div>
        </div>
      )}
      <div className="lastTxtPart">Period trajanja: 06.11. - 18.12.2023.</div>
      {/* </div> */}
    </div>
  );
};

export default MechanismPage;
